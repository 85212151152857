
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { QSpinner, QIntersection } from 'quasar';

import WsButton from '@WS_Components/WsButton.vue';
import WsSpinner from '@WS_Components/WsSpinner.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import { Domain, DomainError, SearchQuery } from './dc.types';
import DomainSearchBar from './DomainSearchBar.vue';
import DomainCheckerCategories from './DomainCheckerCategories.vue';
import {
  checkDomains,
  stopRunningCheck,
} from '@/services/domain-checker.service';
import { logError } from '@/services/logger.service';
import CheckStatusMessage from './CheckStatusMessage.vue';
import DomainsResultTable from './resultsTable/DomainsResultTable.vue';
import { ROUTENAMES } from '@/const.enum';
import { AppState } from '@/store/const';
import DomainResultSkeletonTable from './resultsTable/DomainResultSkeletonTable.vue';
import { Theme } from '@loopia-group/services';
import { get } from 'lodash-es';
import ProductFlowStepper from '@/components/ProductFlowStepper.vue';



@Component({
  components: {
    ProductFlowStepper,
    DomainResultSkeletonTable,
    QSpinner,
    QIntersection,
    WsButton,
    DomainsResultTable,
    DomainCheckerCategories,
    CheckStatusMessage,
    DomainSearchBar,
    WsMessage,
    WsSpinner,
  },
  computed: {
    ...mapState<AppState>({
      searching: (state: AppState) => !!state.domainChecker?.searching,
      endOfStream: (state: AppState) => !!state.domainChecker?.endOfStream,
    }),
    ...mapGetters([
      'dcOnlyFreeDomains',
      'dcAllDomains',
      'dcCheckedDomains',
      'dcCheckedDomainsErrors',
      'cartDomains',
    ]),
  },
})
export default class DomainChecker extends Vue {
  @Prop({ default: null })
  readonly search!: SearchQuery | null;
  searchQuery: SearchQuery | null = null;
  freeOnly = true;
  Theme = Theme;

  cartRoute = ROUTENAMES.CART;
  iwpProductRoute = ROUTENAMES.IWP_PRODUCT;

  // pagination:
  page = 1;
  perPage = 5;
  lastResultOnView = false;

  // state
  readonly searching!: boolean;
  readonly endOfStream!: boolean;
  inputValidationError = '';

  // Getters:
  readonly dcOnlyFreeDomains!: Domain[];
  readonly dcAllDomains!: Domain[];
  readonly dcCheckedDomains!: Domain[];
  readonly dcCheckedDomainsErrors!: DomainError[];
  readonly cartDomains!: string[];

  // Categories
  chosenCategory: string = '';

  onCategoryChange(category: string): void {
    if (category === 'resetFilter') {
      this.chosenCategory = '';

      return;
    }

    this.chosenCategory = category;
  }

  get domains() {
    const getDomainsWithTld = () => {
      const domainsWithTldFilter = ({ name }: Domain) =>
        this.dcAllDomains?.[0].name !== name;
      return this.freeOnly
        ? this.dcOnlyFreeDomains.filter(domainsWithTldFilter)
        : this.allDomains;
    };

    const getDomainsWithoutTld = () =>
      this.freeOnly ? this.dcOnlyFreeDomains : this.allDomains;

    const domains = this.searchQuery?.isWithoutTld
      ? getDomainsWithoutTld()
      : getDomainsWithTld();

    // for reset domain categories
    if (this.chosenCategory === '') {
      return domains;
    }

    return domains?.filter(item =>
      new Set(item.categories?.map(category => category.name)).has(
        this.chosenCategory
      )
    );
  }

  get hideHeader(): boolean {
    return this.domains.length >= 1;
  }

  get showContinueToCart(): boolean {
    return this.cartDomains.length > 0;
  }

  get allDomains() {
    return this.dcCheckedDomains?.[0]?.availableForPurchase
      ? this.dcAllDomains.filter(
        (domain: Domain) => this.dcCheckedDomains?.[0].name !== domain.name
      )
      : this.dcAllDomains;
  }

  // Check if the input has 'unsupported_chars' to hide results and alternatives in DomainChecker
  get containsUnsupportedChars() {
    return get(this.dcCheckedDomainsErrors, '[0].error', []).includes(
      'unsupported_chars'
    );
  }

  // Hooks
  beforeDestroy() {
    stopRunningCheck();

    this.searchQuery = null;
    this.page = 1;
  }

  get searchBarDisabled() {
    return this.searching ? this.dcAllDomains.length < 1 : false;
  }

  get showMainResultRows() {
    return this.dcCheckedDomains?.[0]?.availableForPurchase;
  }

  // method prepared for multicheck
  // get typedDomainsAlreadyChecked() {
  //   if (this.searching && this.searchQuery) {
  //     // if we get all searched domains, we have not to wait for other domains from streaming response
  //     const retrievedDomains = Array.from(
  //       this.domains,
  //       (item: Domain) => item.name
  //     );
  //     return !this.searchQuery.domains.every((d: string) =>
  //       retrievedDomains.includes(d)
  //     );
  //   }

  //   return true;
  // }

  @Watch('search', { immediate: true })
  onSearchChanges(newSearch: SearchQuery | null) {
    if (newSearch?.domains?.length) {
      this.startNewCheck(newSearch);
    }
  }

  startCheck(isNewSearch: boolean, { domains, freeOnly }: any) {
    this.$emit('check-started', { domains, freeOnly });
    checkDomains(isNewSearch, domains, this.page, this.perPage)
      .then(() => {
        //https://github.com/axios/axios#axios-api
        this.loadNextResults();
      })
      .catch(logError);
  }

  startNewCheck({ domains, freeOnly = false }: any) {
    this.inputValidationError = '';
    this.$messageService.clearMessages('domainChecker.general');

    // perform new search
    this.searchQuery = {
      domains,
      isWithoutTld: domains.length === 1 && !domains[0].includes('.'),
      freeOnly,
    };
    this.page = 1;

    this.startCheck(true, { domains, freeOnly });
  }

  loadNextResults() {
    if (
      !this.inputValidationError &&
      !this.searching &&
      this.lastResultOnView &&
      !this.endOfStream &&
      this.searchQuery
    ) {
      // load next results
      this.page = this.page + 1;
      this.startCheck(false, {
        domains: this.searchQuery.domains,
        freeOnly: this.searchQuery.freeOnly,
      });
    }
  }

  onIntersection(event: IntersectionObserverEntry) {
    this.lastResultOnView = event.isIntersecting;

    this.loadNextResults();
  }

  onInputValidationError(error: string) {
    this.inputValidationError = error;
    stopRunningCheck();
  }


  get isWebsiteFlow () {
    return this.$route.query.flow === 'website';
  }

  continueToCart() {
    if (this.isWebsiteFlow) {
      this.$router.push({
        name: this.iwpProductRoute,
        params: { path: 'website' },
        query: {domain: this.$route.query?.qd}
      });
    } else {
      this.$router.push({ name: this.cartRoute });
    }
  }
}
