
import { Component, Prop, Vue } from 'vue-property-decorator';

import mascotProblemCaseImg from '@/assets/images/Cary/cary-with-keyboard.png';
import CountUp from './CountUp.vue';
import { Domain } from './dc.types';
import { Theme } from '@loopia-group/services';

@Component({
  components: { CountUp },
})
export default class CheckStatusMessage extends Vue {
  @Prop()
  readonly domains!: Domain[];
  @Prop(Boolean)
  readonly isWithoutTld!: boolean;
  @Prop(Boolean)
  readonly atLeastOneIsFree!: boolean;
  @Prop(Boolean)
  readonly searchIsDone!: boolean;
  @Prop(String)
  readonly inputValidationError!: string;

  Theme = Theme;
  mascotProblemCaseImg = mascotProblemCaseImg;

  get domain(): Domain {
    return this.domains?.[0] || {};
  }

  get firstDomainError() {
    return this.domains?.[0]?.error || this.inputValidationError;
  }

  get isNotOfferedMessage() {
    return (
      this.firstDomainError === 'error.domain_checker.not_offered' ||
      this.isAlsoNotOffered
    );
  }

  get isAlsoNotOffered(): string | false {
    // BE does understand this example "something.sk.com" as it is ".com" tld with
    // unsupported domain name "something.sk", but we want to comunicate this cases
    // as not offered tlds PNO-2309, in this case it is not offered ".sk.com" tld
    if (this.firstDomainError !== 'validation.domain_name.not_supported') {
      return false;
    }

    const parts = this.domain.name.split('.');
    if (parts.length < 3) {
      return false;
    }
    parts.shift(); // remove first part, so rest parts are considered to be tld
    return parts.join('.');
  }
}
