
import { Component, Vue } from 'vue-property-decorator';
import { QPopupEdit } from 'quasar';

@Component({
  components: {
    QPopupEdit,
  },
})
export default class CategoriesPopup extends Vue {
  hide() {
    (this.$refs.popupEdit as any).hide();
  }
}
