var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"domain-checker-categories row justify-center no-wrap",class:{
    ' scroll-target scroll overflow-auto hide-scrollbar': _vm.mobile || _vm.tablet,
  }},[_c('q-scroll-observer',{attrs:{"horizontal":""}}),_c('q-list',{staticClass:"domain-checker-categories__wrapper row no-wrap",class:{ 'justify-around': _vm.desktop }},[_c('q-btn',{staticClass:"domain-checker-categories__button show-all-btn",class:{
        'is-active': _vm.showAllActive,
        'q-mr-xl': _vm.desktop,
      },attrs:{"clickable":"","unelevated":"","ripple":false,"no-caps":""},on:{"click":function($event){return _vm.changeCategory('resetFilter')}}},[_vm._v(" "+_vm._s(_vm.$t('domain_checker.categories.btn_show_all'))+" ")]),(_vm.desktop)?_vm._l((_vm.visibleCategories),function(item){return _c('div',{key:item.label,staticClass:"category__wrapper no-wrap"},[_c('q-item',{key:item.label,staticClass:"domain-checker-categories__button menu-item-btn row no-wrap",class:{
            'is-active': item.active,
            'q-px-lg': _vm.desktop,
          },attrs:{"clickable":""},on:{"click":function($event){return _vm.changeCategory(item.label)}}},[_c('q-item-section',[_vm._v(" "+_vm._s(item.displayName)+" ")])],1)],1)}):_vm._e(),(_vm.tablet || _vm.mobile)?_vm._l((_vm.domainCheckerCategoriesLocal),function(item){return _c('div',{key:item.label,staticClass:"category__wrapper no-wrap"},[_c('q-item',{key:item.label,staticClass:"domain-checker-categories__button q-px-lg",class:{ 'is-active': item.active },attrs:{"clickable":""},on:{"click":function($event){return _vm.changeCategory(item.label)}}},[_c('q-item-section',{staticClass:"row no-wrap"},[_vm._v(" "+_vm._s(item.displayName)+" ")])],1)],1)}):_vm._e(),(_vm.desktop)?_c('q-btn',_vm._g(_vm._b({ref:"dropdown",staticClass:"domain-checker-categories__button show-more-btn",class:{
        'show-more-active': _vm.showMoreActive || _vm.isSomeOfHiddenActive,
      },attrs:{"unelevated":"","no-caps":"","ripple":false,"disable":_vm.hiddenCategories.length === 0},on:{"click":function($event){_vm.showMoreActive = !_vm.showMoreActive}}},'q-btn',_vm.$attrs,false),_vm.$listeners),[_vm._v(" "+_vm._s(_vm.$t('domain_checker.categories.btn_show_more'))+" "),_c('categories-popup',{ref:"domainPopup",attrs:{"value":_vm.hiddenCategories},on:{"hide":function($event){_vm.showMoreActive = false}}},[_c('domain-categories-popup-menu',{attrs:{"hidden-categories":_vm.hiddenCategories},on:{"change":_vm.changeCategory}})],1)],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }