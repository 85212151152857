var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('q-table',{staticClass:"cmp-domains-result-table ws-table remove-scroll",class:{
    mobile: _vm.mobile,
    'not-mobile': _vm.desktop || _vm.tablet,
  },attrs:{"data":_vm.tableData,"wrap-cells":"","hide-bottom":"","grid":_vm.mobile,"columns":_vm.columns,"rows-per-page-options":[0],"hide-header":_vm.hideHeader,"pagination":{
    page: 1,
    rowsPerPage: 0, // 0 means all rows
  },"flat":""},scopedSlots:_vm._u([(!_vm.mobile && !_vm.hideHeader)?{key:"header",fn:function(props){return [_c('q-tr',{attrs:{"props":props}},[_c('q-th',{key:"domain",staticClass:"col-th-domain",style:({ width: '50%' }),attrs:{"props":props}},[_vm._v(" "+_vm._s(_vm.$t('domain_checker.domain'))+" ")]),_c('q-th',{key:"period",staticClass:"col-th-period",style:({ width: '20%' }),attrs:{"auto-width":"","props":props}},[_vm._v(" "+_vm._s(_vm.$t('cart.col.period.title'))+" ")]),_c('q-th',{key:"price",staticClass:"col-th-price text-right",style:({ width: '20%' }),attrs:{"props":props}},[_vm._v(" "+_vm._s(_vm.$t('domain_checker.price'))+" ")]),_c('q-th',{key:"action",staticClass:"col-th-action text-bold ws-text-dark",style:({ width: '10%' }),attrs:{"props":props}})],1)]}}:null,{key:"body",fn:function(props){return [_c('q-tr',{staticClass:"spacer"}),_c('domain-result-skeleton',{attrs:{"opacity":props.row.opacity}})]}},{key:"item",fn:function(props){return [_c('q-tr',{staticClass:"spacer"}),_c('domain-result-skeleton',{attrs:{"mobile":"","opacity":props.row.opacity}})]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }