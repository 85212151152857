
import { Component, Vue } from 'vue-property-decorator';

import { isEqual } from 'lodash-es';
import DomainChecker from '@/components/domain-checker/DomainChecker.vue';
import { RouteQuery, SearchQuery } from '@/components/domain-checker/dc.types';
import LayoutDefault from './LayoutDefault.vue';
import { constructUrlFromRoute, setQueryParam } from '../utilities';
import { RawLocation } from 'vue-router';

@Component({
  components: {
    LayoutDefault,
    DomainChecker,
  },
})
export default class DomainCheckerView extends Vue {
  get query(): any {
    return (
      this.$route.query || {
        qd: '',
        freeOnly: 'true',
      }
    );
  }

  get domainSearchFromQuery(): SearchQuery | null {
    const { qd: domains, freeOnly } = this.query;

    return domains
      ? ({
        domains: domains
          .toLowerCase()
          .split(',')
          .filter((item: string) => !!item), // trim empty strings
        freeOnly,
      } as SearchQuery)
      : null;
  }

  onCheckStarted({ domains, freeOnly }: any) {
    const query: RouteQuery = {
      qd: domains.join(','),
      freeOnly,
    };

    if (isEqual(this.query, query)) {
      // Don't change URL if there is no change in query
      return;
    }

    let fullUrl = constructUrlFromRoute(
      this.$router,
      this.$route as RawLocation
    );
    fullUrl = setQueryParam(fullUrl, 'qd', this.query!.qd!);
    fullUrl = setQueryParam(fullUrl, 'freeOnly', this.query!.freeOnly!);

    if (history.pushState) {
      // @ts-ignore
      history.pushState({}, null, fullUrl);
    }
  }
}
