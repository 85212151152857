
import {Component, Prop, Vue} from 'vue-property-decorator';
import {QItem, QItemSection, QList} from 'quasar';
import {DomainCheckerCategoriesLocal} from './DomainCheckerCategories.vue';

@Component({
  components: {
    QItem,
    QList,
    QItemSection,
  },
})
export default class DomainCategoriesPopupMenu extends Vue {
  @Prop()
  readonly hiddenCategories?: DomainCheckerCategoriesLocal;
}
