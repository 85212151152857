
import { Component, Vue } from 'vue-property-decorator';
import WsMessage from '@WS_Components/WsMessage.vue';

@Component({
  components: { WsMessage },
})
export default class CountUp extends Vue {
  speedInMs: number = 100;
  newDomainsNumber: number = 0;
  timeoutId: NodeJS.Timeout | undefined = undefined;
  intervalId: NodeJS.Timeout | undefined = undefined;

  mounted() {
    this.startCountUp();
  }

  beforeDestroy() {
    this.reset();
  }

  startCountUp() {
    this.reset();

    this.countUp(this.newDomainsNumber);
  }

  // every 1 sec add 7 - 13 domains
  countUp(count: number) {
    this.newDomainsNumber = count;
    const min = 7;
    const max = 13;

    // count up to infinity
    this.timeoutId = setTimeout(() => this.countUp(count + 1), this.speedInMs);

    this.intervalId = setInterval(() => {
      this.speedInMs = Math.round(
        1000 / Math.floor(Math.random() * (max - min + 1) + min)
      );
    }, 3000); // change this variable every 3s
  }

  reset() {
    this.newDomainsNumber = 0;
    clearTimeout(this.timeoutId!);
    clearInterval(this.intervalId!);
  }
}
