
import { Component, Prop, Vue } from 'vue-property-decorator';
import { QSkeleton, QTd, QTr } from 'quasar';

@Component({
  components: {
    QTr,
    QTd,
    QSkeleton,
  },
})
export default class DomainResultSkeleton extends Vue {
  @Prop(Number) readonly opacity!: number;

  readonly mobile!: boolean;
}
