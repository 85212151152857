
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import { QTable, QTr, QTh } from 'quasar';

import DomainResultSkeleton from './DomainResultSkeleton.vue';

@Component({
  components: {
    DomainResultSkeleton,
    QTable: (QTable as unknown) as VueConstructor<Vue>,
    QTr,
    QTh,
  },
})
export default class DomainResultSkeletonTable extends Vue {
  @Prop(Boolean) readonly hideHeader!: boolean;

  readonly mobile!: boolean;
  readonly tablet!: boolean;
  readonly desktop!: boolean;

  columns = [
    {
      name: 'domain',
      align: 'left',
    },
    {
      name: 'period',
      align: 'left',
    },
    {
      name: 'price',
      align: 'left',
    },
    {
      name: 'action',
      align: 'left',
    },
  ];

  get tableData() {
    return [{ opacity: 1 }, { opacity: 0.6 }, { opacity: 0.5 }];
  }
}
