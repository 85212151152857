
import { TranslateResult } from 'vue-i18n';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { QItem, QList, QItemSection, QBtn, QScrollObserver } from 'quasar';
import { DomainCheckerCategory } from '../../store/const';
import DomainCategoriesPopupMenu from './DomainCategoriesPopupMenu.vue';
import CategoriesPopup from './CategoriesPopup.vue';

type DomainCheckerCategoryLocal = {
  label: string;
  active: boolean;
  displayName: string | TranslateResult;
};

export type DomainCheckerCategoriesLocal = Array<DomainCheckerCategoryLocal>;

@Component({
  components: {
    CategoriesPopup,
    DomainCategoriesPopupMenu,
    QBtn,
    QItem,
    QList,
    QItemSection,
    QScrollObserver,
  },
  computed: {
    ...mapState(['domainCheckerCategories']),
  },
})
export default class DomainCheckerCategories extends Vue {
  //@ts-ignore strictPropertyInitialization
  readonly domainCheckerCategories: Array<DomainCheckerCategory>;
  domainCheckerCategoriesLocal: DomainCheckerCategoriesLocal | null = null;

  readonly mobile!: boolean;
  readonly tablet!: boolean;
  readonly desktop!: boolean;

  showAllActive = true;
  showMoreActive = false;

  get visibleCategories() {
    return this.domainCheckerCategoriesLocal?.slice(0, 7);
  }

  get hiddenCategories() {
    return this.domainCheckerCategoriesLocal?.slice(
      7,
      this.domainCheckerCategoriesLocal?.length
    );
  }

  get isSomeOfHiddenActive() {
    return this.hiddenCategories?.filter(item => item.active).length;
  }

  @Watch('domainCheckerCategories', { immediate: true })
  onDomainCheckerCategories(val: DomainCheckerCategories | null): void {
    if (val) {
      this.transformData();
    }
  }

  transformData() {
    this.domainCheckerCategoriesLocal = this.domainCheckerCategories.map(
      item => ({
        label: item.name,
        active: false,
        displayName: this.$t(
          'domain_checker.categories.items.' + item.translationKey
        ),
      })
    );
  }

  setActive(categoryName: string) {
    this.domainCheckerCategoriesLocal?.forEach(item => {
      if (categoryName === item.label) {
        item.active = true;
        return;
      }
      item.active = false;
    });
  }

  changeCategory(categoryName: string): void {
    if (categoryName === 'resetFilter') {
      this.showAllActive = true;
      this.setActive('');
      this.$emit('changeCategory', categoryName);

      return;
    }

    this.showAllActive = false;
    this.showMoreActive = false;
    this.setActive(categoryName);
    this.$emit('changeCategory', categoryName);
  }
}
