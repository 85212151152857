var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cmp-check-status-message ws-dark q-px-sm"},[(_vm.domains.length === 1 || _vm.isWithoutTld || _vm.inputValidationError)?[((_vm.isWithoutTld && _vm.atLeastOneIsFree) || _vm.domain.availableForPurchase)?_c('div',{staticClass:"domain-is-free"},[(_vm.isWithoutTld)?_c('div',[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('domain_checker.choose_alternatives_title'))+" ")]),_c('div',{staticClass:"q-mb-4xl"},[_c('small',[_vm._v(" "+_vm._s(_vm.$t('domain_checker.without_tld_text'))+" ")])])]):_c('div',[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('domain_checker.domain_free'))+" "),_c('span',{staticClass:"text-ws-highlighted"},[_vm._v(_vm._s(_vm.$t('domain_checker.free_status')))])]),_c('div',{staticClass:"q-mb-4xl"},[_c('small',[_vm._v(" "+_vm._s(_vm.$t('domain_checker.hurry_up_text.part1'))+" "),_c('count-up'),_vm._v(" "+_vm._s(_vm.$t('domain_checker.hurry_up_text.part2'))+" ")],1)])])]):_vm._e(),(_vm.firstDomainError)?_c('div',{staticClass:"domain-has-error"},[(_vm.isNotOfferedMessage)?[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('domain_checker.not_offered.title1'))+" "),_c('span',{staticClass:"text-ws-highlighted"},[_vm._v("\"."+_vm._s(_vm.isAlsoNotOffered || _vm.domain.tld)+"\"")]),_vm._v(" "+_vm._s(_vm.$t('domain_checker.not_offered.title2'))+" ")]),_c('div',{staticClass:"q-mb-4xl"},[_c('small',[_vm._v(" "+_vm._s(_vm.$t('domain_checker.not_offered.message'))+" ")])])]:(
          _vm.firstDomainError === 'validation.domain_name.unsupported_chars'
        )?[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('domain_checker.unsuported_chars.title1'))+" "),_c('span',{staticClass:"text-ws-error"},[_vm._v(_vm._s(_vm.$t('domain_checker.unsuported_chars.status')))]),_vm._v(" "+_vm._s(_vm.$t('domain_checker.unsuported_chars.title2'))+" ")]),_c('div',{staticClass:"q-mb-4xl"},[_c('small',[_vm._v(" "+_vm._s(_vm.$t('domain_checker.unsuported_chars.message'))+" ")])]),(_vm.isTheme(_vm.Theme.CARY))?_c('img',{staticClass:"mascot-problem-case",attrs:{"src":_vm.mascotProblemCaseImg,"alt":""}}):_vm._e()]:[_c('h4',[_vm._v(" "+_vm._s(_vm.$t(_vm.firstDomainError))+" ")])]],2):_c('div',[(
          _vm.isWithoutTld
            ? !_vm.atLeastOneIsFree && _vm.searchIsDone
            : !_vm.get(_vm.domains, '[0].availableForPurchase')
        )?_c('div',{staticClass:"domain-is-occupied"},[(!_vm.isWithoutTld)?_c('h4',[_vm._v(" "+_vm._s(_vm.$t('domain_checker.occupied.title'))+" "),_c('span',{staticClass:"text-occupied text-ws-warn"},[_vm._v(_vm._s(_vm.$t('domain_checker.occupied.status')))])]):_vm._e(),_c('div',{staticClass:"q-mb-4xl"},[_c('small',[_vm._v(" "+_vm._s(_vm.$t('domain_checker.occupied.text'))+" ")])])]):_vm._e()])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }